import jwtDecode from 'jwt-decode';
import { OpenAPI } from '../api';
//

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null) => {

  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    OpenAPI.TOKEN = accessToken;
  } else {
    localStorage.removeItem('accessToken');
    OpenAPI.TOKEN = undefined;
  }
};

export { isValidToken, setSession };
