import { BrowserRouter, Routes } from 'react-router-dom';
import { sceneToRoutes, } from './scenes';

export default function Router() {
  return <BrowserRouter>
    <Routes>
      {sceneToRoutes}
    </Routes>
  </BrowserRouter>;
}
