import LoadingScreen from 'components/loading-screen';
import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

const Loadable: any = (Component: React.ElementType) => (props: any) => {

  return (
    <Suspense fallback={<LoadingScreen/>}>
      <Component {...props} />
    </Suspense>
  );
};

const LoadingPage = lazy(() => import('../components/loading-screen'))

const routes = {
  world: Loadable(lazy(() => import('../pages/world'))),
  roadmap: Loadable(lazy(() => import('../pages/roadmap'))),
  __loading: LoadingPage,
  '*': Loadable(lazy(() => import('../pages/404'))),
}

export const sceneToRoutes = (<React.Fragment>
      <Route path='/' element={routes.world({})}/>
  {Object.entries(routes).map(([key, Val] ) => <Route key={key === '*' ? '__unrouted_pages' : key} path={key} element={<Val/>}/>)}
</React.Fragment>);
