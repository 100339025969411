/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentUser } from '../models/CurrentUser';
import type { TokenRequest } from '../models/TokenRequest';
import type { User } from '../models/User';
import type { UsernameRequest } from '../models/UsernameRequest';
import type { UserPage } from '../models/UserPage';
import type { UserPrint } from '../models/UserPrint';
import type { UserUpdate } from '../models/UserUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @returns User Create user successfully
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: UserPrint,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CurrentUser Retrieve user successfully
     * @throws ApiError
     */
    public static currentUser(): CancelablePromise<CurrentUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/current',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static destroy({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/destroy/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns User Retrieve user successfully
     * @throws ApiError
     */
    public static retrieve({
        id,
    }: {
        /**
         * User's unique id
         */
        id: string,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/detail/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns UserPage List users successfully
     * @throws ApiError
     */
    public static list({
        page,
        pageSize,
        sortBy,
        mobile,
        createdAtGte,
        createdAtLte,
        updatedAtGte,
        updatedAtLte,
    }: {
        page?: number,
        pageSize?: number,
        sortBy?: string,
        mobile?: string,
        createdAtGte?: string,
        createdAtLte?: string,
        updatedAtGte?: string,
        updatedAtLte?: string,
    }): CancelablePromise<UserPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/list',
            query: {
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
                'mobile': mobile,
                'created_at_gte': createdAtGte,
                'created_at_lte': createdAtLte,
                'updated_at_gte': updatedAtGte,
                'updated_at_lte': updatedAtLte,
            },
        });
    }

    /**
     * @returns User Update user mobile successfully
     * @throws ApiError
     */
    public static updateMobile({
        requestBody,
    }: {
        requestBody: TokenRequest,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/update-mobile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User Update user name successfully
     * @throws ApiError
     */
    public static updateUsername({
        requestBody,
    }: {
        requestBody: UsernameRequest,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/update-username',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User Update user successfully
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UserUpdate,
    }): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/update/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns boolean Existed username
     * @throws ApiError
     */
    public static usernameExist({
        requestBody,
    }: {
        requestBody: UsernameRequest,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users/username-exist',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
