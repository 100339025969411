/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentCallbackResponse } from '../models/PaymentCallbackResponse';
import type { PendingOrderOutput } from '../models/PendingOrderOutput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * @returns PaymentCallbackResponse
     * @throws ApiError
     */
    public static paymentCallback({
        requestBody,
    }: {
        requestBody: PendingOrderOutput,
    }): CancelablePromise<PaymentCallbackResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/payment/cb/wechat',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
