import styled from '@emotion/styled';
import { Box } from 'theme-ui';

export const FrameContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
