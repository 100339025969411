import React from 'react'; 
import { Box, Text } from 'theme-ui';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { FrameContainer } from './styled';


const Container = styled(Box)({
  width: '50px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
})

const Cat = styled(Box)({
  position: 'relative',
  height: '30px',
  width: '33.9px',
})

const Ear = styled(Box)({
  position: 'absolute',
  top: '-14%',
  height: '30%',
  width: '32%',
  background: 'hotpink',
  '::before, ::after': {
    content: '""',
    position: 'absolute',
    bottom: '61%',
    height: '10%',
    width: '3%',
    borderRadius: '50%',
  },
  '::after': {
    transformOrigin: '50% 100%',
  },
});

const EarLeft = styled(Ear)({
  left: '2%',
  borderRadius: '35% 65% 0% 0% / 100% 100% 0% 0%',
  transform: 'rotate(-13deg)',
  '::before, ::after': {
    right: '50%'
  },
  '::after': {
    transform: 'rotate(-45deg)',
  }
})

const EarRight = styled(Ear)({
  right: '2%',
  borderRadius: '65% 35% 0% 0% / 100% 100% 0% 0%',
  transform: 'rotate(13deg)',
  '::before, ::after': {
  },
  '::after': {
    transform: 'rotate(45deg)',
  }
})

const Face = styled(Box)({
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: '#230861',
  borderRadius: '50% 50% 50% 50% / 40% 40% 60% 60%',
})

const blink = keyframes`
  0% { height: 0; }
  90% { height: 0; }
  92.5% { height: 100%; }
  95% { height: 0; }
  97.5% { height: 100%; }
  100% { height: 0; }
`

const Eye = styled(Box)({
  position: 'absolute',
  top: '35%',
  height: '30%',
  width: '31%',
  background: 'hotpink',
  '::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: 0,
    width: '100%',
    borderRadius: '0 0 50% 50% / 0 0 40% 40%',
    background: '#230861',
    animation: `${blink} 4s infinite ease-in`,
  },
  '::before': {
    content: '""',
    position: 'absolute',
    top: '60%',
    height: '10%',
    width: '15%',
    background: 'hotpink',
    borderRadius: '50%',
  }
});

const EyeLeft = styled(Eye)({
  borderRadius: '40% 50% 50% 60% / 30% 30% 90% 60%',
  left: '7%',
  '::before': {
    right: '0%',
  }
})


const EyeRight = styled(Eye)({
  borderRadius: '50% 50% 50% 50% / 30% 30% 60% 90%',
  right: '7%',
  '::before': {
    left: '0%',
  }
})

const lookAround = keyframes`
  0% { transform: translate(0);}
  5% { transform: translate(50%, -25%);}
  10% { transform: translate(50%, -25%); }
  15% { transform: translate(-100%, -25%); }
  20% { transform: translate(-100%, -25%); }
  25% { transform: translate(0, 0); }
  100% { transform: translate(0, 0); }
`;

const EyePupil = styled(Box)({
  position: 'absolute',
  top: '35%',
  height: '30%',
  width: '40%',
  background: '#230861',
  borderRadius: '50% 50% 50% 50% / 0 0 100% 100%',
  animation: `${lookAround} 4s infinite`,
  '::after': {
    content: '""',
    position: 'absolute',
    top: '-25%',
    right: '0%',
    height: '60%',
    width: '50%',
    borderRadius: '50%',
    background: '#230861',
  },
  '::before': {
    content: '""',
    position: 'absolute',
    top: '-35%',
    left: '0%',
    height: '100%',
    width: '50%',
    borderRadius: '50%',
    background: '#230861',
  },
});

const EyePupilLeft = styled(EyePupil)({
  right: '30%',
  
})


const EyePupilRight = styled(EyePupil)({
  left: '30%',
  
})

const Muzzle = styled(Box)({
  position: 'absolute',
  top: '75%',
  left: '52%',
  height: '14%',
  width: '10%',
  background: 'hotpink',
  transform: 'translateX(-50%)',
  borderRadius: '50% 50% 50% 50% / 40% 30% 70% 50%',
});

const preloader = keyframes`
  100% { color: yellow; }
`;

const LoadingText = styled(Text)((props) => ({
  fontSize: props.theme.fontSizes[0],
  color: 'cyan', 
  animation: `${preloader} 0.6s ease-in-out alternate .4s infinite`,
  transform: 'scale(0.8);'
}))

export default function LoadingScreen() {
  const CatInstance = () => <Container ><Cat>
    <EarLeft/>
    <EarRight/>
    <Face>
      <EyeLeft>
        <EyePupilLeft/>
      </EyeLeft>
      <EyeRight>
        <EyePupilRight/>
      </EyeRight>
      <Muzzle/>
    </Face>
  </Cat>
  <LoadingText>加载中</LoadingText>
  </Container>
  const a = Array.from({length: 5}, (_, i) => i + 1);

  return (
    <FrameContainer>
      
      {a.map((_, index) => <CatInstance key={index}/>)}
    </FrameContainer>
  );
}
