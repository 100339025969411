/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Order } from '../models/Order';
import type { OrderPage } from '../models/OrderPage';
import type { OrderWithGoodDetail } from '../models/OrderWithGoodDetail';
import type { PendingOrderInput } from '../models/PendingOrderInput';
import type { PendingOrderOutput } from '../models/PendingOrderOutput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderService {

    /**
     * @returns Order
     * @throws ApiError
     */
    public static retrieve({
        id,
    }: {
        /**
         * Orders's unique id
         */
        id: string,
    }): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/detail/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OrderPage
     * @throws ApiError
     */
    public static list({
        page,
        pageSize,
        sortBy,
    }: {
        page?: number,
        pageSize?: number,
        sortBy?: string,
    }): CancelablePromise<OrderPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/list',
            query: {
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
            },
        });
    }

    /**
     * @returns OrderWithGoodDetail
     * @throws ApiError
     */
    public static myOrders(): CancelablePromise<Array<OrderWithGoodDetail>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/my',
        });
    }

    /**
     * @returns Order
     * @throws ApiError
     */
    public static queryByPaymentKey({
        paymentKey,
    }: {
        /**
         * Orders's payment key
         */
        paymentKey: string,
    }): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/payment-key/{payment_key}',
            path: {
                'payment_key': paymentKey,
            },
        });
    }

    /**
     * @returns PendingOrderOutput
     * @throws ApiError
     */
    public static pendingOrder({
        requestBody,
    }: {
        requestBody: PendingOrderInput,
    }): CancelablePromise<PendingOrderOutput> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/pending',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
