/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { AddressPrint } from '../models/AddressPrint';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddressService {

    /**
     * @returns Address Create user successfully
     * @throws ApiError
     */
    public static create({
        requestBody,
    }: {
        requestBody: AddressPrint,
    }): CancelablePromise<Address> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/addresses',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Address List my address successfully
     * @throws ApiError
     */
    public static myAddress(): CancelablePromise<Address> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/addresses/my',
            errors: {
                401: `Unauthorized`,
                404: `NotFound`,
            },
        });
    }

    /**
     * @returns Address
     * @throws ApiError
     */
    public static update({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: AddressPrint,
    }): CancelablePromise<Address> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/addresses/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
