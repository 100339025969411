/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MobileRequest } from '../models/MobileRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SmsService {

    /**
     * @returns boolean Existed mobile number
     * @throws ApiError
     */
    public static sendCode({
        requestBody,
    }: {
        requestBody: MobileRequest,
    }): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sms/send-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
