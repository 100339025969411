/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiRequestOptions } from './ApiRequestOptions';
import type { ApiResult } from './ApiResult';

export class ApiError extends Error {
    public readonly url: string;
    public readonly status: number;
    public readonly statusText: string;
    public readonly body: any;
    public readonly request: ApiRequestOptions;

    constructor(request: ApiRequestOptions, response: ApiResult, message: string) {
        super(message);

        this.name = 'ApiError';
        this.url = response.url;
        this.status = response.status;
        this.statusText = response.statusText;
        this.body = response.body;
        this.request = request;
        this.message = translateError(this.body)
        
    }
}

type ErrorCode = 'PermissionDenied' | 'NotFound' | 'AlreadyExisted' | 'InternalError'

interface ErrorBody {
  error: ErrorCode,
  detail: String,
}

const ErrorCodeMapping = {
  PermissionDenied: '权限不够',
  NotFound: '不存在',
  Alreadyexisted: '已经存在',
  InternalError: '内部错误',
}

const ErrorDetailMapping = {
  'User with code not existed': '用户名验证码不匹配',
  'Wrong verify code': '验证码错误',
}

function translateError(body: ErrorBody): string {
  const {error, detail} = body;
  let msg = ErrorDetailMapping[detail as keyof typeof ErrorDetailMapping];
  if (msg !== undefined) {return msg}
  msg = ErrorCodeMapping[error as keyof typeof ErrorCodeMapping];
  if (msg !== undefined) {return msg}
  return ErrorCodeMapping.InternalError
}