import React from 'react';
import './App.css';
import { AuthProvider } from 'contexts/JWTContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './routes';
import ThemeConfig from './theme';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeConfig>
        <AuthProvider>
            <Router />
        </AuthProvider>
      </ThemeConfig>
    </QueryClientProvider>
  );
}

export default App;
